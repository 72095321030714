import {Component, inject, input, OnInit, signal, viewChildren} from '@angular/core';
import {Button} from "primeng/button";
import {CurrencyPipe, NgStyle} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {ProgressBarModule} from "primeng/progressbar";
import {TableModule} from "primeng/table";
import {v4 as uuidv4} from 'uuid';
import {CostingProcessService, Process, ResponseModelProcess} from "../../api/auto-gen";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../shared/services/toast-service.service";
import {OptionUpsertService} from "../option-upsert-service.service";
import {HttRowComponent} from "./htt-row/htt-row.component";
import {HttHeaderRowComponent} from "./htt-header-row/htt-header-row.component";
import {httRowMinWidth} from "./column-widths";

@Component({
  selector: 'app-htt-component',
  standalone: true,
  imports: [
    Button,
    CurrencyPipe,
    PrimeTemplate,
    ProgressBarModule,
    TableModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    HttRowComponent,
    HttHeaderRowComponent,
    NgStyle
  ],
  templateUrl: './htt-component.component.html',
  styleUrl: './htt-component.component.scss',
  providers: [DialogService, DynamicDialogRef, CostingProcessService, ToastService],
})
export class HttComponentComponent implements OnInit {
  httProcesses = input.required<Process[]>();
  newHttProcesses = signal<string[]>([]);
  allValid = signal<boolean>(true);

  httRows = viewChildren(HttRowComponent);
  mainProcess: string = 'HTT';
  protected optionUpsertService = inject(OptionUpsertService);
  protected readonly uuidv4 = uuidv4;
  protected readonly httRowMinWidth = httRowMinWidth;
  private costingProcessService = inject(CostingProcessService);
  private toastService = inject(ToastService);

  ngOnInit() {
    this.fetchHTTProcesses();

  }

  validateAllForms(): boolean {

    this.allValid.set(true);
    this.httRows().forEach(child => {
      child.form?.markAllAsTouched();
      if (!child.form?.valid) {
        this.allValid.set(false);
      }
    });

    return this.allValid();
  }

  fetchHTTProcesses() {
    this.optionUpsertService.fetchProcesses();
    this.newHttProcesses.set([]);
  }

  onDelete(uuid: string) {
    if (this.newHttProcesses().includes(uuid)) {
      this.newHttProcesses.set(this.newHttProcesses().filter(e => e != uuid));
    } else if (this.httProcesses().some(e => e.process_uuid == uuid) && this.optionUpsertService.projectOptionUuid()) {
      this.costingProcessService.deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete({
        processUuid: uuid,
        projectOptionUuid: this.optionUpsertService.projectOptionUuid()!,
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcess) => {
              if (response) {
                this.toastService.pushSuccessToast('Process deleted successfully!');
                this.fetchHTTProcesses();
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }
}
