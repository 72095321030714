import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loading: boolean = false;
  private totalRequests = 0;

  constructor() {
  }

  startLoading() {
    this.loading = true;
    this.totalRequests++;
  }

  endLoading() {
    this.totalRequests--;
    if (this.totalRequests <= 0) {
      this.loading = false;
    }
  }

  getLoading(): boolean {
    return this.loading;
  }
}
