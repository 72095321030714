import {Component, input} from '@angular/core';
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {ReferenceFileProperties} from "../../../api/auto-gen";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'app-reference-file-properties-table',
  standalone: true,
  imports: [
    PrimeTemplate,
    TableModule,
    DecimalPipe
  ],
  templateUrl: './reference-file-properties-table.component.html',
  styleUrl: './reference-file-properties-table.component.scss'
})
export class ReferenceFilePropertiesTableComponent {
  referenceFileProperties = input.required<ReferenceFileProperties>();
}
