import {Component, computed, inject, input, OnInit, output, signal} from '@angular/core';
import {
  dragHandleWidth,
  hourlyRateMinWidth,
  otherProcessesRowMinWidth,
  positionWidth,
  processWidth,
  setupTimeWidth,
  speedWidth,
  taskWidth,
  workCenterWidth
} from "../../htt-component/column-widths";
import {Button} from "primeng/button";
import {CurrencyPipe, DecimalPipe, NgStyle} from "@angular/common";
import {DropdownChangeEvent, DropdownModule} from "primeng/dropdown";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  GeneralService,
  MainProcess,
  Parameter,
  Process,
  ProcessCreate,
  ResponseModelMainProcessList,
  ResponseModelTaskList,
  ResponseModelWorkcenterList,
  Task,
  Workcenter
} from "../../../api/auto-gen";
import {ToastService} from "../../../shared/services/toast-service.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {OptionUpsertService} from "../../option-upsert-service.service";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {AdjustParameterDialogComponent} from "../../adjust-parameter-dialog/adjust-parameter-dialog.component";

@Component({
  selector: 'app-process-row',
  standalone: true,
  imports: [
    Button,
    CurrencyPipe,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgStyle,
    DecimalPipe
  ],
  templateUrl: './process-row.component.html',
  styleUrl: './process-row.component.scss'
})
export class ProcessRowComponent implements OnInit {
  process = input<Process | undefined>(undefined);
  processUuid = input.required<string>();
  site = input.required<string>();
  allValid = input.required<boolean>();
  processChanged = output();
  onDelete = output<string>();
  form: FormGroup | undefined;
  mainProcesses = signal<MainProcess[]>([]);
  workcenters = signal<Workcenter[]>([]);
  tasks = signal<Task[]>([]);
  notEditableProcesses: string[] = ['HTT', 'FOR', 'SIZ']
  editable = computed(() => {
    if (!this.process()) {
      return true;
    }
    return !this.notEditableProcesses.includes(this.process()!.mainprocess);

  });
  protected readonly workCenterWidth = workCenterWidth;
  protected readonly taskWidth = taskWidth;
  protected readonly speedWidth = speedWidth;
  protected readonly setupTimeWidth = setupTimeWidth;
  protected readonly processWidth = processWidth;
  protected readonly positionWidth = positionWidth;
  protected readonly dragHandleWidth = dragHandleWidth;
  protected readonly hourlyRateMinWidth = hourlyRateMinWidth;
  protected readonly rowMinWidth = otherProcessesRowMinWidth;
  private generalService = inject(GeneralService);
  private toastService = inject(ToastService);
  private dialogService = inject(DialogService);
  private dynamicDialogRef = inject(DynamicDialogRef);
  private optionUpsertService = inject(OptionUpsertService);

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      mainprocess: [this.process()?.mainprocess, Validators.required],
      order: [this.process()?.order ?? this.optionUpsertService.getNextOrder(), Validators.required],
      workcenter: [this.process()?.workcenter, Validators.required],
      task: [this.process()?.task, Validators.required],
      speed: [this.process()?.speed ?? 10, Validators.required],
      setup_time: [this.process()?.setup_time, Validators.required],
      hourly_rate: [this.process()?.hourly_rate, Validators.required],
      cost_per_piece: [this.process()?.cost_per_piece ?? 10.0, Validators.required],
      parameters: [this.process()?.parameters ?? []],
    });
    this.fetchMainProcesses();
    this.fetchWorkcenters();
    this.fetchTask();

  }

  async onSave(): Promise<void> {
    if (this.form?.valid && this.optionUpsertService.projectOptionUuid() && this.form?.dirty) {
      if (this.process()) {
        await this.optionUpsertService.updateProcess(this.processUuid(), this.form.value);
      } else {
        await this.optionUpsertService.addProcess(this.form.value);
      }
    }
  }

  fetchMainProcesses() {
    this.generalService.getMainProcessesMainprocessesGet()
      .pipe()
      .subscribe({
          next: (response: ResponseModelMainProcessList) => {
            let mainProcessWithoutNotEditable = (response.data?.main_processes ?? []).filter(e => !this.notEditableProcesses.includes(e.name));
            this.mainProcesses.set(mainProcessWithoutNotEditable);
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  fetchWorkcenters(): void {
    if (this.form?.get('mainprocess')?.value) {
      this.generalService.getWorkcentersWorkcentersGet({
        site: this.site(),
        mainProcess: this.form!.get('mainprocess')!.value
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelWorkcenterList) => {
              this.workcenters.set(response.data?.workcenter ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }

  }

  fetchTask(): void {
    if (this.form?.get('mainprocess')?.value && this.form?.get('workcenter')?.value) {
      this.generalService.getTasksTasksGet({
        site: this.site(),
        mainProcess: this.form!.get('mainprocess')!.value,
        workcenter: this.form!.get('workcenter')!.value
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelTaskList) => {
              this.tasks.set(response.data?.tasks ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  mainProcessChanged(_: DropdownChangeEvent) {
    this.form?.patchValue({
      workcenter: null,
      task: null,
    });

    this.fetchWorkcenters();
  }

  workCenterChanged(event: DropdownChangeEvent) {
    const selectedWorkcenter = this.workcenters().find(wc => wc.workcenter_uuid === event.value);
    this.form?.patchValue({
      task: null,
      hourly_rate: selectedWorkcenter?.hourlyrate,
    });

    this.fetchTask();
  }

  async taskChanged(event: DropdownChangeEvent) {
    let processParameters = event.value.parameters.map((e: Parameter) => ({
      constant_uuid: e.constant_uuid,
      name: e.name,
      value: e.value,
      adjusted_value: e.value,
    }));
    this.form?.patchValue({
      setup_time: event.value.setuptime,
      parameters: processParameters,
    });
    if (this.form?.value.task && this.optionUpsertService.projectOptionUuid()) {
      const selectedWorkCenter = this.workcenters().find(wc => wc.workcenter_uuid === this.form?.value.workcenter);
      if (selectedWorkCenter) {
        try {
          let formulaResult = await this.optionUpsertService.evaluateFormula(
            selectedWorkCenter,
            this.form!.value.task,
            this.form!.value.parameters,
          );
          if (formulaResult?.data) {
            this.form?.patchValue({
              speed: formulaResult.data.speed.toFixed(0),
            });

            if (this.form.valid) {
              await this.onSave();
              this.processChanged.emit();
            }
          }
        } catch (e) {
          this.form?.patchValue({
            task: null,
          });
        }
      }
    }
  }

  handleDeletion() {

    this.dynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        confirmMessage: "Are you sure you want to delete this process?"
      },
    },);

    this.dynamicDialogRef.onClose.subscribe((deleteProcess: boolean) => {
      if (deleteProcess) {
        this.onDelete.emit(this.processUuid());
      }
    });
  }

  adjustParameter() {
    const selectedWorkCenter = this.workcenters().find(wc => wc.workcenter_uuid === this.form?.value.workcenter);
    if (selectedWorkCenter && this.process()) {
      this.dynamicDialogRef = this.dialogService.open(AdjustParameterDialogComponent, {
        data: {process: this.process()!, workcenter: selectedWorkCenter, task: this.process()!.task},
        width: '700px'
      },);

      this.dynamicDialogRef.onClose.subscribe(async (processCreate: ProcessCreate) => {
        if (processCreate) {
          await this.optionUpsertService.updateProcess(this.processUuid(), processCreate);
        }
      });
    }
  }
}
