@if (form) {
  <form [formGroup]="form" class="htt-row-div flex gap-3 w-full" [ngStyle]="{'min-width.px': rowMinWidth}">
    @if (!process()) {
      <div class="flex flex-column align-items-start justify-content-center gap-3"
           [ngStyle]="{'min-width.px': dragHandleWidth}">

      </div>
    }
    <div class="flex flex-column align-items-start justify-content-center gap-3"
         [ngStyle]="{'min-width.px': positionWidth}">
      <p class="text-color">{{ form.get('order')?.value ?? '/' }}</p>
    </div>
    <div class="flex flex-column align-items-start justify-content-center gap-3"
         [ngStyle]="{'min-width.px': processWidth}">
      @if (editable()) {
        <p-dropdown [options]="mainProcesses()" class="w-full"
                    formControlName="mainprocess"
                    (onChange)="mainProcessChanged($event)"
                    optionValue="name"
                    appendTo="body"
                    optionLabel="name"
                    placeholder="Choose"/>
        @if (form.get('mainprocess')?.invalid && form.get('mainprocess')?.touched) {
          <small class="p-error">
            Required
          </small>
        }
      } @else {
        <p class="text-color">{{ this.process()?.mainprocess }}</p>
      }

    </div>
    <div class="flex flex-column align-items-start justify-content-center gap-3"
         [ngStyle]="{'min-width.px': workCenterWidth, 'max-width.px': workCenterWidth}">
      @if (editable()) {
        <p-dropdown [options]="workcenters()" class="w-full"
                    formControlName="workcenter"
                    (onChange)="workCenterChanged($event)"
                    optionValue="workcenter_uuid"
                    appendTo="body"
                    optionLabel="name"
                    placeholder="Choose"/>
        @if (form.get('workcenter')?.invalid && form.get('workcenter')?.touched) {
          <small class="p-error">
            Required
          </small>
        }
      } @else {
        <p class="text-color">{{ this.process()?.workcenter_name }}</p>
      }

    </div>
    <div class="flex flex-column align-items-start justify-content-center gap-3"
         [ngStyle]="{'min-width.px': taskWidth}">
      @if (editable()) {
        <p-dropdown [options]="tasks()" class="w-full"
                    formControlName="task"
                    (onChange)="taskChanged($event)"
                    optionLabel="name"
                    appendTo="body"
                    placeholder="Choose"/>
        @if (form.get('task')?.invalid && form.get('task')?.touched) {
          <small class="p-error">
            Required
          </small>
        }
      } @else {
        <p class="text-color">{{ process()?.task?.name ?? '' }}</p>
      }

    </div>
    <div class="flex align-items-center gap-3" [ngStyle]="{'min-width.px': speedWidth}">
      <p class="text-color">{{ form.get('speed')?.value ? (form.get('speed')?.value | number: '0.0-0') : '/' }}</p>
    </div>
    <div class="flex align-items-center gap-3" [ngStyle]="{'min-width.px': setupTimeWidth}">
      <p class="text-color">
        @if (form.get('setup_time')?.value != null) {
          {{ form.get('setup_time')?.value }} h
        } @else {
          /
        }
      </p>
    </div>
    <div class="flex align-items-center gap-3 flex-grow-1" [ngStyle]="{'min-width.px': hourlyRateMinWidth}">
      <p class="text-color flex-grow-1">
        @if (form.get('hourly_rate')?.value) {
          {{ form.get('hourly_rate')?.value | currency: 'EUR':'' }} €
        } @else {
          /
        }
      </p>
      @if (editable()) {
        @if (process()) {
          <p-button (onClick)="adjustParameter()" class="adjust-button" severity="secondary">Adjust Parameter
          </p-button>
        }

        <i (click)="handleDeletion()" class="pi pi-trash cursor-pointer"></i>
      }
    </div>
  </form>
}
