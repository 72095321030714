import {Component, computed, inject, OnInit, signal, viewChildren} from '@angular/core';
import {Button} from "primeng/button";
import {CurrencyPipe, NgClass, NgStyle} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {v4 as uuidv4} from 'uuid';
import {CostingProcessService, Process, ProcessReorder, ResponseModelProcess} from "../../api/auto-gen";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormsModule} from "@angular/forms";
import {HttRowComponent} from "../htt-component/htt-row/htt-row.component";
import {OptionUpsertService} from "../option-upsert-service.service";
import {ToastService} from "../../shared/services/toast-service.service";
import {HttHeaderRowComponent} from "../htt-component/htt-header-row/htt-header-row.component";
import {ProcessHeaderRowComponent} from "./process-header-row/process-header-row.component";
import {ProcessRowComponent} from "./process-row/process-row.component";
import {dragHandleWidth, otherProcessesRowMinWidth} from "../htt-component/column-widths";
import {OrderListModule} from "primeng/orderlist";
import {DragDropModule} from "primeng/dragdrop";
import {CdkDragDrop} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-other-processes-component',
  standalone: true,
  imports: [
    Button,
    CurrencyPipe,
    DropdownModule,
    PrimeTemplate,
    TableModule,
    FormsModule,
    HttHeaderRowComponent,
    HttRowComponent,
    ProcessHeaderRowComponent,
    ProcessRowComponent,
    NgStyle,
    OrderListModule,
    DragDropModule,
    NgClass
  ],
  templateUrl: './other-processes-component.component.html',
  styleUrl: './other-processes-component.component.scss',
  providers: [DialogService, DynamicDialogRef],
})
export class OtherProcessesComponentComponent implements OnInit {

  newProcesses = signal<string[]>([]);
  allValid = signal<boolean>(true);

  processRows = viewChildren(ProcessRowComponent);
  protected optionUpsertService = inject(OptionUpsertService);
  sortedProcesses = computed<Process[]>(() => {
    return (this.optionUpsertService.processList()?.processes ?? []).sort((a, b) => a.order - b.order);
  });
  protected readonly uuidv4 = uuidv4;
  protected readonly rowMinWidth = otherProcessesRowMinWidth;
  protected readonly dragHandleWidth = dragHandleWidth;
  private costingProcessService = inject(CostingProcessService);
  private toastService = inject(ToastService);

  ngOnInit() {
    this.fetchProcesses();

  }

  fetchProcesses() {
    this.optionUpsertService.fetchProcesses();
    this.newProcesses.set([]);
  }

  validateAllForms(): boolean {
    this.allValid.set(true);
    this.processRows().forEach(child => {
      child.form?.markAllAsTouched();
      if (!child.form?.valid) {
        this.allValid.set(false);
      }
    });

    return this.allValid();
  }

  onDelete(uuid: string) {
    if (this.newProcesses().includes(uuid)) {
      this.newProcesses.set(this.newProcesses().filter(e => e != uuid));
    } else if (this.optionUpsertService.processList()?.processes.some(e => e.process_uuid == uuid) && this.optionUpsertService.projectOptionUuid()) {
      this.costingProcessService.deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete({
        processUuid: uuid,
        projectOptionUuid: this.optionUpsertService.projectOptionUuid()!,
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcess) => {
              if (response) {
                this.toastService.pushSuccessToast('Process deleted successfully!');
                this.fetchProcesses();
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  drop(event: CdkDragDrop<any, any>) {
    let listCopy = [...this.sortedProcesses()];
    const item = listCopy.splice(event.previousIndex, 1)[0];
    listCopy.splice(event.currentIndex, 0, item);
    let processReorders: ProcessReorder[] = [];
    for (let item of listCopy) {
      processReorders.push({process_uuid: item.process_uuid, sort: listCopy.indexOf(item)});
    }
    this.optionUpsertService.reOrderProcesses({process_reorders: processReorders});
  }
}
