<div class="other-processes-card">
  <div class="other-processes-div flex flex-column gap-3">
    <div class="header flex align-items-center gap-3">
      <p class="title flex-grow-1">Other Processes</p>
      <p-button (onClick)="newProcesses().push(uuidv4())">Add Process</p-button>
    </div>
    <div class="other-processes-table-div">
      <div [ngStyle]="{'min-width.px': rowMinWidth}">
        @if (optionUpsertService.projectOption()) {
          <app-process-header-row/>
          <div cdkDropList (cdkDropListDropped)="drop($event)">
            @for (process of sortedProcesses(); track process) {
              <div cdkDrag class="flex gap-3 w-full process-row"
                   [cdkDragDisabled]="['FOR', 'SIZ', 'HTT'].includes(process.mainprocess)">
                <div class="flex flex-column align-items-start justify-content-center gap-3"
                     [ngStyle]="{'min-width.px': dragHandleWidth}">
                  @if (!['FOR', 'SIZ', 'HTT'].includes(process.mainprocess)) {
                    <i class="pi pi-equals cursor-move" cdkDragHandle></i>
                  }
                </div>
                <app-process-row [site]="optionUpsertService.projectOption()!.site"
                                 [process]="process" [allValid]="allValid()" [processUuid]="process.process_uuid"
                                 (onDelete)="onDelete($event)" (processChanged)="fetchProcesses()" class="flex-grow-1"
                />
              </div>
            }
          </div>
          @for (newProcess of newProcesses(); track newProcess) {
            <app-process-row [site]="optionUpsertService.projectOption()!.site"
                             [processUuid]="newProcess" [allValid]="allValid()" (onDelete)="onDelete($event)"
                             (processChanged)="fetchProcesses()"/>
          }

        }
      </div>
    </div>
    <div class="summary-div flex justify-content-end">
      <div>
        <div class="grid">
          <p class="sub-title col-4">Processes</p>
          <p class="sub-title col-8">Cost per Piece/100 pc</p>
        </div>
        <div class="grid">
          <p class="card-data col-4">{{ optionUpsertService.processList()?.nr_processes ?? 0 }}</p>
          <p
            class="card-data col-8">{{ optionUpsertService.processList()?.total_cost_per_piece ?? 0 | currency: 'EUR':'' }}
            €</p>
        </div>
      </div>
    </div>
  </div>
</div>
