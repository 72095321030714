import {Component, inject, OnInit, signal} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormulaResult, Parameter, Process, ProcessCreate, ProcessParameter, Task, Workcenter} from "../../api/auto-gen";
import {TableModule} from "primeng/table";
import {CurrencyPipe, NgStyle} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {Button} from "primeng/button";
import {keyWidth, ogValueWidth} from "./adjust_parameters_column-widths";
import {InputNumberInputEvent, InputNumberModule} from "primeng/inputnumber";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OptionUpsertService} from "../option-upsert-service.service";

export interface AdjustParameterDataModel {
  process: Process;
  workcenter: Workcenter;
  task: Task;
}

@Component({
  selector: 'app-adjust-parameter-dialog',
  standalone: true,
  imports: [
    TableModule,
    CurrencyPipe,
    DropdownModule,
    Button,
    NgStyle,
    InputNumberModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './adjust-parameter-dialog.component.html',
  styleUrl: './adjust-parameter-dialog.component.scss'
})
export class AdjustParameterDialogComponent implements OnInit {
  process = signal<Process | undefined>(undefined);
  workcenter = signal<Workcenter | undefined>(undefined);
  task = signal<Task | undefined>(undefined);
  formulaResult = signal<FormulaResult | null | undefined>(undefined);
  processCreate = signal<ProcessCreate | undefined>(undefined);
  processParameters = signal<ProcessParameter[]>([])
  protected readonly keyWidth = keyWidth;
  protected readonly ogValueWidth = ogValueWidth;
  private dynamicDialogConfig: DynamicDialogConfig<AdjustParameterDataModel> = inject(DynamicDialogConfig);
  private ref = inject(DynamicDialogRef);
  private optionUpsertService = inject(OptionUpsertService);

  ngOnInit() {
    this.process.set(this.dynamicDialogConfig.data?.process);
    this.workcenter.set(this.dynamicDialogConfig.data?.workcenter);
    this.task.set(this.dynamicDialogConfig.data?.task);
    if (!this.process()) {
      this.processParameters.set([]);
    }

    this.formulaResult.set({speed: this.process()!.speed, cost_per_piece: this.process()!.cost_per_piece});
    if ((this.process()!.parameters ?? []).length > 0) {
      this.processParameters.set(this.process()!.parameters);
    } else if (this.process()!.task) {
      this.processParameters.set(this.parametersToProcessParameters(this.process()!.task.parameters));
    } else {
      this.processParameters.set([]);
    }
    this.processCreate.set({
      mainprocess: this.process()!.mainprocess,
      order: this.process()!.order,
      workcenter: this.process()!.workcenter,
      task: this.process()!.task,
      speed: this.formulaResult()!.speed,
      setup_time: this.process()!.setup_time,
      hourly_rate: this.process()!.hourly_rate,
      parameters: this.processParameters(),
    });
  }

  parametersToProcessParameters(parameters: Parameter[]): ProcessParameter[] {
    return parameters.map((e: Parameter) => ({
        constant_uuid: e.constant_uuid,
        name: e.name,
        value: e.value,
        adjusted_value: e.editable ? e.value : null,
      })
    );
  }

  checkIfProcessParameterIsEditable(parameter: ProcessParameter): boolean {
    if (!this.process()?.task) {
      return false
    }
    let taskParameter = this.process()!.task.parameters.find(e => e.constant_uuid == parameter.constant_uuid);
    return taskParameter?.editable ?? false;
  }

  cancel() {
    this.ref.close(undefined);
  }

  save() {
    this.ref.close(this.processCreate());
  }

  async changeAdjustedValue(event: InputNumberInputEvent, parameter: ProcessParameter) {
    event.originalEvent.preventDefault();
    let processParameterCopy = [...this.processParameters()];
    let index = processParameterCopy.findIndex(e => e.constant_uuid == parameter.constant_uuid);
    if (index >= 0) {
      processParameterCopy[index] = {...parameter, adjusted_value: event.value as number | null};
      this.processCreate.set({...this.processCreate()!, parameters: processParameterCopy});
      if (this.task() && this.workcenter()) {
        let formulaResult = await this.optionUpsertService.evaluateFormula(this.workcenter()!, this.task()!, processParameterCopy);
        let result: FormulaResult | undefined = undefined;
        if (formulaResult?.data) {
          result = {
            speed: Math.round(formulaResult.data.speed),
            cost_per_piece: formulaResult.data.cost_per_piece,
          }
        }
        this.formulaResult.set(result);
      }

    }

  }
}
