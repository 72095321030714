import {Component, inject, OnInit, signal} from '@angular/core';
import {Button} from "primeng/button";
import {CreateProjectComponentComponent} from "../create-project-component/create-project-component.component";
import {DialogModule} from "primeng/dialog";
import {PrimeTemplate} from "primeng/api";
import {ProjectListComponentComponent} from "./project-list-component/project-list-component.component";
import {
  ProjectStateFilterButtonsComponentComponent
} from "./project-state-filter-buttons-component/project-state-filter-buttons-component.component";
import {
  GeneralService,
  Project,
  ProjectOverviewService,
  ResponseGetUserUserGet,
  ResponseModelProjectList,
  ResponseModelUserList,
  User
} from "../../api/auto-gen";
import {ToastService} from "../../shared/services/toast-service.service";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {
  ProjectCardComponentComponent
} from "./project-list-component/project-card-component/project-card-component.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MultiSelectModule} from "primeng/multiselect";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-project-page',
  standalone: true,
  imports: [
    Button,
    CreateProjectComponentComponent,
    DialogModule,
    PrimeTemplate,
    ProjectListComponentComponent,
    ProjectStateFilterButtonsComponentComponent,
    DropdownModule,
    InputTextModule,
    ProjectCardComponentComponent,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule
  ],
  templateUrl: './project-page.component.html',
  styleUrl: './project-page.component.scss',
  providers: [ProjectOverviewService, ToastService, GeneralService, DialogService, DynamicDialogRef],
})
export class ProjectPageComponent implements OnInit {
  projects = signal<Project[]>([]);
  stateFilter = signal<'open' | 'won' | 'lost' | null | undefined>('open');
  fullTextSearch = '';
  users = signal<User[]>([]);
  selectedUser: User | undefined;
  private projectOverviewService = inject(ProjectOverviewService);
  private generalService = inject(GeneralService);
  private toastService = inject(ToastService);
  private dialogService = inject(DialogService);
  private dynamicDialogRef = inject(DynamicDialogRef);

  ngOnInit(): void {
    this.fetchProjects();
    this.fetchUsers();
  }

  fetchUsers() {
    this.generalService.getUserUserGet({
      searchString: '',
    })
      .pipe()
      .subscribe({
          next: (response: ResponseGetUserUserGet) => {
            if (response) {
              this.users.set((response as ResponseModelUserList).data?.users ?? []);
            }
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  fetchProjects() {
    this.projectOverviewService.getProjectsProjectProjectsGet({
      projectStatus: this.stateFilter(),
      projectName: this.fullTextSearch,
      user: this.selectedUser?.email_address,
    })
      .pipe()
      .subscribe({
          next: (response: ResponseModelProjectList) => {
            if (response.data) {
              this.projects.set(response.data.projects);
            }
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  stateFilterChanged(state: 'open' | 'won' | 'lost' | null | undefined) {
    this.stateFilter.set(state);
    this.fetchProjects();
  }

  addProject() {
    this.dynamicDialogRef = this.dialogService.open(CreateProjectComponentComponent, {});

    this.dynamicDialogRef.onClose.subscribe((fetchProjects: boolean) => {
      if (fetchProjects) {
        this.fetchProjects();
      }
    });
  }
}
