import {Component, effect, inject, OnInit, signal, viewChild} from '@angular/core';
import {FileUpload, FileUploadHandlerEvent, FileUploadModule} from "primeng/fileupload";
import {CurrencyPipe, NgOptimizedImage} from "@angular/common";
import {TableModule} from "primeng/table";
import {SliderModule} from "primeng/slider";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RadioButtonModule} from "primeng/radiobutton";
import {
  ReferenceFilePropertiesTableComponent
} from "./reference-file-properties-table/reference-file-properties-table.component";
import {LayersTableComponent} from "./layers-table/layers-table.component";
import {CustomSliderComponent} from "./custom-slider/custom-slider.component";
import {OptionUpsertService} from "../option-upsert-service.service";
import {StlModelViewerComponent} from "./stl-model-viewer/stl-model-viewer.component";
import {CostingProcessService, GeneralService, ResponseModelReferenceFileProperties} from "../../api/auto-gen";
import {ToastService} from "../../shared/services/toast-service.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-reference-file-component',
  standalone: true,
  imports: [
    FileUploadModule,
    NgOptimizedImage,
    CurrencyPipe,
    TableModule,
    SliderModule,
    FormsModule,
    RadioButtonModule,
    ReactiveFormsModule,
    ReferenceFilePropertiesTableComponent,
    LayersTableComponent,
    CustomSliderComponent,
    StlModelViewerComponent
  ],
  templateUrl: './reference-file-component.component.html',
  styleUrl: './reference-file-component.component.scss',
  providers: [CostingProcessService, ToastService, GeneralService]
})
export class ReferenceFileComponentComponent implements OnInit {
  fileBufferArray = signal<ArrayBuffer | undefined>(undefined);
  form: FormGroup | undefined;
  protected fileUpload = viewChild(FileUpload);
  protected optionUpsertService = inject(OptionUpsertService);
  private costingProcessService = inject(CostingProcessService);
  private toastService = inject(ToastService);
  private fb = inject(FormBuilder);
  private httpClient = inject(HttpClient);

  constructor() {
    effect(async () => {
      let properties = this.optionUpsertService.projectOption()?.reference_file_properties
      let setting = this.optionUpsertService.projectOption()?.reference_file_settings;
      let newFormValue = {
        density: setting?.density ?? 0,
        pressure: setting?.pressure ?? 0,
        min_step_height: setting?.min_step_height ?? 0,
        min_layer_area: setting?.min_layer_area ?? 0,
        max_angle_on_punch: setting?.max_angle_on_punch ?? 0,
        max_layer_bend: setting?.max_layer_bend ?? 0,
        axes_layouting: setting?.axes_layouting ?? properties?.axes_layouting ?? 'none',
        upside_down: setting?.upside_down ?? properties?.upside_down ?? 'original',
      };
      if (this.form && this.form.value === newFormValue) {
        this.form.setValue(newFormValue);
        if (this.form?.value) {
          this.optionUpsertService.detectLayers(this.form?.value);
        }
      }

      if (this.optionUpsertService.projectOption()?.reference_file && !this.fileBufferArray()) {
        await this.downloadFile(this.optionUpsertService.projectOption()!.reference_file!);
      }

    })
  }

  ngOnInit() {
    let properties = this.optionUpsertService.projectOption()?.reference_file_properties;
    let setting = this.optionUpsertService.projectOption()?.reference_file_settings;
    this.form = this.fb.group({
      density: [setting?.density ?? 0, Validators.required],
      pressure: [setting?.pressure ?? 0, Validators.required],
      min_step_height: [setting?.min_step_height ?? 0, Validators.required],
      min_layer_area: [setting?.min_layer_area ?? 0, Validators.required],
      max_angle_on_punch: [setting?.max_angle_on_punch ?? 0, Validators.required],
      max_layer_bend: [setting?.max_layer_bend ?? 0, Validators.required],
      axes_layouting: [setting?.axes_layouting ?? properties?.axes_layouting ?? 'none', Validators.required],
      upside_down: [setting?.upside_down ?? properties?.upside_down ?? 'original', Validators.required],
    });
    if (this.form?.value && this.optionUpsertService.projectOption()?.reference_file) {
      this.optionUpsertService.detectLayers(this.form?.value);
    }
  }

  async downloadFile(url: string) {
    let arrayBuffer = await firstValueFrom(this.httpClient.get(url, {responseType: 'arraybuffer'}));
    this.fileBufferArray.set(arrayBuffer);
  }

  async uploadFile(event: FileUploadHandlerEvent) {
    this.fileBufferArray.set(undefined);
    if (this.optionUpsertService.projectOptionUuid()) {
      for (let file of event.files) {
        if (file.name.endsWith('.stl') && !this.fileBufferArray()) {
          this.fileBufferArray.set(await file.arrayBuffer());
        } else if (file.name.endsWith('.stp')) {
          this.convertStpToStl(file);
        }
      }

      let that = this;
      setTimeout(function () {
          that.costingProcessService.uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost({
            projectOptionUuid: that.optionUpsertService.projectOptionUuid()!,
            file: event.files[0]
          })
            .pipe()
            .subscribe({
                next: (_: ResponseModelReferenceFileProperties) => {
                  that.optionUpsertService.fetchProjectOption();
                  that.toastService.pushSuccessToast('File uploaded successfully!');
                },
                error: (error: any) => {
                  that.toastService.pushErrorToast(error);
                },
              },
            );
        }
        , 500
      );
    }
  }

  convertStpToStl(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.httpClient.post(`${environment.apiEndpoint}/convert_stp_to_stl/`, formData,
      {
        responseType: 'blob',
      }
    ).pipe()
      .subscribe({
          next: async (response: Blob) => {
            if (!this.fileBufferArray()) {
              this.fileBufferArray.set(await response.arrayBuffer());
            }
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  updateLayouting(event: string) {
    if (event && this.form) {
      this.form!.patchValue({
        axes_layouting: event,
      })
    }
  }

  updateUpsideDown(event: string) {
    if (event && this.form) {
      this.form!.patchValue({
        upside_down: event,
      })
    }
  }
}
