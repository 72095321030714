export const dragHandleWidth = 50;
export const positionWidth = 100;
export const processWidth = 150;
export const workCenterWidth = 150;
export const taskWidth = 150;
export const speedWidth = 100;
export const setupTimeWidth = 100;
export const hourlyRateMinWidth = 300;

export const httRowMinWidth = workCenterWidth + taskWidth + speedWidth + setupTimeWidth + hourlyRateMinWidth + 4 * 16;
export const otherProcessesRowMinWidth = dragHandleWidth + positionWidth + processWidth + workCenterWidth + taskWidth + speedWidth + setupTimeWidth + hourlyRateMinWidth + 7 * 16;
