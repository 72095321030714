import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  OnInit,
  signal,
  viewChild
} from '@angular/core';
import {Button} from "primeng/button";
import {MenuItem} from "primeng/api";
import {StepsModule} from "primeng/steps";
import {SpaceSetupComponentComponent} from "./space-setup-component/space-setup-component.component";
import {ReferenceFileComponentComponent} from "./reference-file-component/reference-file-component.component";
import {TabMenuModule} from "primeng/tabmenu";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormingComponentComponent} from "./forming-component/forming-component.component";
import {SizingComponentComponent} from "./sizing-component/sizing-component.component";
import {HttComponentComponent} from "./htt-component/htt-component.component";
import {OtherProcessesComponentComponent} from "./other-processes-component/other-processes-component.component";
import {ActivatedRoute, Router} from "@angular/router";
import {OptionUpsertService} from "./option-upsert-service.service";

@Component({
  selector: 'app-option-upsert-page-component',
  standalone: true,
  imports: [
    Button,
    StepsModule,
    SpaceSetupComponentComponent,
    ReferenceFileComponentComponent,
    TabMenuModule,
    ReactiveFormsModule,
    FormingComponentComponent,
    SizingComponentComponent,
    HttComponentComponent,
    OtherProcessesComponentComponent
  ],
  templateUrl: './option-upsert-page.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrl: './option-upsert-page.component.scss',
  providers: [OptionUpsertService],
})
export class OptionUpsertPageComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  optionUpsertService = inject(OptionUpsertService);
  items: MenuItem[] = [
    {
      label: 'Space setup',
      id: 'spaceSetup',
    },
    {
      label: 'Reference File',
      id: 'referenceFile',
      disabled: true,
    },
    {
      label: 'Forming',
      id: 'forming',
      disabled: true,
    },
    {
      label: 'Sizing',
      id: 'sizing',
      disabled: true,
    },
    {
      label: 'HTT',
      id: 'htt',
      disabled: true,
    },
    {
      label: 'Other Processes',
      id: 'otherProcesses',
      disabled: true,
    }
  ];
  activeId = signal<string>('spaceSetup');
  httTab = viewChild(HttComponentComponent);
  otherProcessesTab = viewChild(OtherProcessesComponentComponent);
  private fb = inject(FormBuilder);
  optionForm: FormGroup = this.fb.group({
    spaceSetup: this.fb.group({
      name: ['', Validators.required],
      site: ['', Validators.required],
      lot_quantity: [undefined, Validators.required],
      yearly_quantity: [undefined, Validators.required],
    }),
    forming: this.fb.group(
      {
        mainprocess: ['FOR', Validators.required],
        order: [0, Validators.required],
        workcenter: ['', Validators.required],
        task: [null, Validators.required],
        speed: [null, Validators.required],
        setup_time: [null, Validators.required],
        hourly_rate: [null, Validators.required],
        parameters: [[]],
      }),
    sizing: this.fb.group({
      mainprocess: ['SIZ', Validators.required],
      order: [0, Validators.required],
      workcenter: ['', Validators.required],
      task: [null, Validators.required],
      speed: [null, Validators.required],
      setup_time: [null, Validators.required],
      hourly_rate: [null, Validators.required],
      parameters: [[]],
    })
  });

  constructor(private cdr: ChangeDetectorRef) {
    effect(() => {
      if (this.optionUpsertService.projectOption()) {
        const spaceSetupGroup = this.optionForm.get('spaceSetup') as FormGroup;
        spaceSetupGroup.setValue({
          name: this.optionUpsertService.projectOption()?.name ?? '',
          site: this.optionUpsertService.projectOption()?.site ?? '',
          lot_quantity: this.optionUpsertService.projectOption()?.lot_quantity ?? undefined,
          yearly_quantity: this.optionUpsertService.projectOption()?.yearly_quantity ?? undefined,
        })
        spaceSetupGroup.get('site')?.disable();
        spaceSetupGroup.get('lot_quantity')?.disable();
        spaceSetupGroup.get('yearly_quantity')?.disable();
      }
      let forProcess = this.optionUpsertService.forProcess();
      if (forProcess) {
        const formingGroup = this.optionForm.get('forming') as FormGroup;
        formingGroup?.setValue({
          mainprocess: forProcess.mainprocess,
          order: forProcess.order,
          workcenter: forProcess.workcenter,
          task: forProcess.task,
          speed: forProcess.speed,
          setup_time: forProcess.setup_time,
          hourly_rate: forProcess.hourly_rate,
          parameters: forProcess.parameters,
        })
        let sizProcess = this.optionUpsertService.sizProcess();
        if (sizProcess) {
          const sizingGroup = this.optionForm.get('sizing') as FormGroup;
          sizingGroup?.setValue({
            mainprocess: sizProcess.mainprocess,
            order: sizProcess.order,
            workcenter: sizProcess.workcenter,
            task: sizProcess.task,
            speed: sizProcess.speed,
            setup_time: sizProcess.setup_time,
            hourly_rate: sizProcess.hourly_rate,
            parameters: sizProcess.parameters,
          })
        }
      }
    }, {allowSignalWrites: true});

    effect(() => {
      if (this.optionUpsertService.allowEditing()) {
        let isUpdate = this.optionUpsertService.projectOption() != undefined;
        this.items = [
          {
            label: 'Space setup',
            id: 'spaceSetup',
          },
          {
            label: 'Reference File',
            id: 'referenceFile',
            disabled: !isUpdate,
          },
          {
            label: 'Forming',
            id: 'forming',
            disabled: !(isUpdate && this.optionUpsertService.projectOption()?.reference_file != undefined),
          },
          {
            label: 'Sizing',
            id: 'sizing',
            disabled: !(isUpdate && (this.optionUpsertService.processList()?.processes ?? []).some(e => e.mainprocess == 'FOR')),
          },
          {
            label: 'HTT',
            id: 'htt',
            disabled: !(isUpdate && ((this.optionUpsertService.processList()?.processes ?? []).some(e => e.mainprocess == 'SIZ') || (this.optionUpsertService.projectOption()?.skip_sizing ?? false))),
          },
          {
            label: 'Other Processes',
            id: 'otherProcesses',
            disabled: !(isUpdate && (this.optionUpsertService.processList()?.processes ?? []).some(e => e.mainprocess == 'HTT')),
          }
        ];
      } else if (this.optionUpsertService.projectOption() && this.optionUpsertService.project()) {
        this.activeId.set('otherProcesses');
        this.items = [
          {
            label: 'Space setup',
            id: 'spaceSetup',
            disabled: true,
          },
          {
            label: 'Reference File',
            id: 'referenceFile',
            disabled: true,
          },
          {
            label: 'Forming',
            id: 'forming',
            disabled: true,
          },
          {
            label: 'Sizing',
            id: 'sizing',
            disabled: true,
          },
          {
            label: 'HTT',
            id: 'htt',
            disabled: true,
          },
          {
            label: 'Other Processes',
            id: 'otherProcesses',
            disabled: false,
          }
        ];
      }
      this.cdr.markForCheck();
    }, {allowSignalWrites: true});
  }

  get active() {
    return this.items[this.items.findIndex(e => e.id == this.activeId())]
  };

  ngOnInit() {
    let projectUuid = this.route.parent?.snapshot.params['id'];
    let projectOptionUuid = this.route.snapshot.params['optionId'];
    if (projectUuid) {
      this.optionUpsertService.initializeUpsertService(projectUuid, projectOptionUuid);
    }

    if (projectOptionUuid && this.optionUpsertService.allowEditing()) {
      this.route.queryParams.subscribe(params => {
        const currentStepId = params['step'];
        let itemIndex = this.items.findIndex(e => e.id === currentStepId);
        if (itemIndex >= 0 && this.items[itemIndex].id) {
          this.activeId.set(this.items[itemIndex].id!);
        }

      });
    }
  }

  async onActiveItemChange(event: MenuItem) {
    await this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {step: event.id},
        queryParamsHandling: 'merge'
      }
    );
  }

  async nextStep() {
    switch (this.activeId()) {
      case 'spaceSetup':
        const spaceSetupGroup = this.optionForm.get('spaceSetup') as FormGroup;
        spaceSetupGroup.markAllAsTouched();
        if (spaceSetupGroup.invalid) {
          return;
        }
        if (!this.optionUpsertService.projectOptionUuid()) {
          this.optionUpsertService.addProjectOption(spaceSetupGroup.value)
        } else if (spaceSetupGroup.get('name')?.dirty) {
          this.optionUpsertService.updateProjectOption(spaceSetupGroup.get('name')?.value, undefined);
        }
        break;
      case 'referenceFile':
        if (!this.optionUpsertService.projectOption()?.reference_file) {
          return;
        }
        break;
      case 'forming':
        const formingGroup = this.optionForm.get('forming') as FormGroup;
        formingGroup.markAllAsTouched();
        if (formingGroup.invalid) {
          return;
        }
        try {
          if (!this.optionUpsertService.forProcess()) {
            await this.optionUpsertService.addProcess(formingGroup.value);
          } else if (formingGroup.dirty) {
            await this.optionUpsertService.updateFORProcess(formingGroup.value);
          }
          break;
        } catch (e) {
          return;
        }
      case 'sizing':
        const sizingGroup = this.optionForm.get('sizing') as FormGroup;
        if (this.optionUpsertService.skipSizing.dirty) {
          this.optionUpsertService.updateProjectOption(undefined, this.optionUpsertService.skipSizing.value ?? false);
        }
        if (!(this.optionUpsertService.skipSizing.value ?? false)) {
          sizingGroup.markAllAsTouched();
          if (sizingGroup.invalid) {
            return;
          }
          try {
            if (!this.optionUpsertService.sizProcess()) {
              await this.optionUpsertService.addProcess(sizingGroup.value);
            } else if (sizingGroup.dirty) {
              await this.optionUpsertService.updateSIZProcess(sizingGroup.value);
            }
          } catch (e) {
            return;
          }
        }
        break;
      case 'htt':
        let allValid = this.httTab()?.validateAllForms();
        if (!allValid || this.optionUpsertService.httProcesses().length == 0) {
          return;
        }
        break;
      case 'otherProcesses':
        let allProcessesValid = this.otherProcessesTab()?.validateAllForms();
        if (!allProcessesValid) {
          return;
        } else {
          await this.router.navigate(["../"], {relativeTo: this.route});
        }
        break;
    }

    let indexOfActive = this.items.indexOf(this.active);
    this.items[indexOfActive + 1].disabled = false;
    await this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {step: this.items[indexOfActive + 1].id},
        queryParamsHandling: 'merge'
      }
    );
  }

  async previousStep() {
    let indexOfActive = this.items.indexOf(this.active);
    await this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {step: this.items[indexOfActive - 1].id},
        queryParamsHandling: 'merge'
      }
    );
  }
}
