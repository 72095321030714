import {Component, inject, input, output} from '@angular/core';
import {ProjectOption} from "../../../../api/auto-gen";
import {TagModule} from "primeng/tag";
import {Button} from "primeng/button";
import {CurrencyPipe, DatePipe, NgClass, NgOptimizedImage} from "@angular/common";
import {DockModule} from "primeng/dock";
import {OverlayPanelModule} from "primeng/overlaypanel";

@Component({
  selector: 'app-project-option-card-component',
  standalone: true,
  imports: [
    TagModule,
    Button,
    NgOptimizedImage,
    DockModule,
    OverlayPanelModule,
    DatePipe,
    NgClass,
    CurrencyPipe
  ],
  templateUrl: './project-option-card-component.component.html',
  styleUrl: './project-option-card-component.component.scss',
  providers: [DatePipe],
})
export class ProjectOptionCardComponentComponent {
  projectOption = input.required<ProjectOption>();
  isProjectOpen = input.required<boolean>();
  onDuplicateOption = output<string>();
  onArchiveOption = output<string>();

  date = inject(DatePipe);

  duplicateOption(event: MouseEvent) {
    event.stopPropagation();
    if (this.projectOption().project_option_uuid) {
      this.onDuplicateOption.emit(this.projectOption().project_option_uuid!);
    }
  }

  archiveOption(event: MouseEvent) {
    event.stopPropagation();
    if (this.projectOption().project_option_uuid) {
      this.onArchiveOption.emit(this.projectOption().project_option_uuid!);
    }
  }
}
