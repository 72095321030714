<p-table [value]="[referenceFileProperties()]">
  <ng-template pTemplate="header">
    <tr>
      <th class="text-color" style="width: 200px">Feature</th>
      <th class="text-color table-value">Value</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body">
    <tr>
      <td class="text-color">Weight</td>
      <td class="text-color table-value">{{ referenceFileProperties().weight | number: '0.0-3' }} g</td>
    </tr>
    <tr>
      <td class="text-color">APress</td>
      <td class="text-color table-value">{{ referenceFileProperties().apress | number: '0.0-3' }}
        mm2
      </td>
    </tr>
    <tr>
      <td class="text-color">FPress</td>
      <td class="text-color table-value">{{ referenceFileProperties().fpress | number: '0.0-3' }}
        kN@if (referenceFileProperties().fpresst) {
          <span>({{ referenceFileProperties().fpresst | number: '0.0-3' }} t)</span>
        }</td>
    </tr>
    <tr>
      <td class="text-color">BBox-x</td>
      <td class="text-color table-value">{{ referenceFileProperties().bbox_x | number: '0.0-3' }} mm</td>
    </tr>
    <tr>
      <td class="text-color">BBox-y</td>
      <td class="text-color table-value">{{ referenceFileProperties().bbox_y | number: '0.0-3' }} mm</td>
    </tr>
    <tr>
      <td class="text-color">BBox-z</td>
      <td class="text-color table-value">{{ referenceFileProperties().bbox_z | number: '0.0-3' }} mm</td>
    </tr>
    <tr>
      <td class="text-color">Volume</td>
      <td class="text-color table-value">{{ referenceFileProperties().volume | number: '0.0-3' }} mm3</td>
    </tr>
    <tr>
      <td class="text-color">ASurface</td>
      <td class="text-color table-value">{{ referenceFileProperties().asurface | number: '0.0-3' }} mm2</td>
    </tr>
    <tr>
      <td class="text-color">#Cores</td>
      <td class="text-color table-value">{{ referenceFileProperties().nr_cores | number: '0.0-3' }}</td>
    </tr>
    <tr>
      <td class="text-color">#Triangles(STL)</td>
      <td class="text-color table-value">{{ referenceFileProperties().nr_triangles | number: '0.0-3' }}</td>
    </tr>
  </ng-template>
</p-table>
