@if (form) {
  <form [formGroup]="form">
    <div class="space-setup-div flex flex-column gap-3">
      <div class="name-div card grid">
        <div class="col-8 flex flex-column gap-3">
          <p class="card-title">
            Option Name
          </p>
          <input formControlName="name" class="w-full" pInputText placeholder="Name">
          @if (form.get('name')?.touched && form.get('name')?.invalid) {
            <small class="p-error">
              Required
            </small>
          }
        </div>
        <div class="col-2 flex flex-column gap-3">
          <p class="card-title">
            Lot Quantity
          </p>
          <p-inputNumber class="w-full number-input" formControlName="lot_quantity" mode="decimal"
                         [minFractionDigits]="0"
                         [maxFractionDigits]="4" [locale]="'en-US'"/>
          @if (form.get('lot_quantity')?.touched && form.get('lot_quantity')?.invalid) {
            <small class="p-error">
              Required
            </small>
          }
        </div>
        <div class="col-2 flex flex-column gap-3">
          <p class="card-title">
            Yearly Quantity
          </p>
          <p-inputNumber class="w-full number-input" formControlName="yearly_quantity" mode="decimal"
                         [minFractionDigits]="0"
                         [maxFractionDigits]="4" [locale]="'en-US'"/>
          @if (form.get('yearly_quantity')?.touched && form.get('yearly_quantity')?.invalid) {
            <small class="p-error">
              Required
            </small>
          }
        </div>
      </div>
      <div class="location-div card flex flex-column gap-4">
        <div
          class="card-header flex flex-column justify-content-center sm:flex-row gap-3 sm:align-items-center sm:justify-content-between">
          <p class="card-title">
            Choose Location
          </p>
          <span class="p-input-icon-left search-field w-full sm:w-24rem">
            <i class="pi pi-search"></i>
            <input (input)="onFullTextFilterChange($event)" class=" w-full" pInputText placeholder="Search">
          </span>
        </div>
        @if (form.get('site')?.touched && form.get('site')?.invalid) {
          <small class="p-error">
            Required
          </small>
        }
        <div class="grid">
          @for (site of filteredSites; track site) {
            <div class="field-checkbox col-12 xl:col-2 lg:col-3 md:col-4 sm:col-6">
              <div class="field-checkbox-div flex justify-content-between cursor-pointer"
                   (click)="onSiteDivClicked(site)">
                <label [for]="site" class="ml-2">
                  {{ site }}
                </label>
                <p-radioButton
                  [inputId]="site"
                  [value]="site"
                  formControlName="site"/>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </form>
}

