@if (form) {
  <form [formGroup]="form" class="htt-row-div flex gap-3 w-full">
    <div class="flex flex-column align-items-start gap-3" [ngStyle]="{'width.px': workCenterWidth}">
      <p-dropdown [options]="workcenters()" class="w-full"
                  formControlName="workcenter"
                  (onChange)="workCenterChanged($event)"
                  optionValue="workcenter_uuid"
                  optionLabel="name"
                  appendTo="body"
                  placeholder="Choose"/>
      @if (form.get('workcenter')?.invalid && form.get('workcenter')?.touched) {
        <small class="p-error">
          Required
        </small>
      }
    </div>
    <div class="flex flex-column align-items-start gap-3" [ngStyle]="{'width.px': taskWidth}">
      <p-dropdown [options]="tasks()" class="w-full"
                  formControlName="task"
                  (onChange)="taskChanged($event)"
                  optionLabel="name"
                  appendTo="body"
                  placeholder="Choose"/>
      @if (form.get('task')?.invalid && form.get('task')?.touched) {
        <small class="p-error">
          Required
        </small>
      }
    </div>
    <div class="flex align-items-center gap-3" [ngStyle]="{'width.px': speedWidth}">
      <p class="text-color">{{ form.get('speed')?.value ? (form.get('speed')?.value | number: '0.0-0') : '/' }}</p>
    </div>
    <div class="flex align-items-center gap-3" [ngStyle]="{'width.px': setupTimeWidth}">
      <p class="text-color">
        @if (form.get('setup_time')?.value != null) {
          {{ form.get('setup_time')?.value }} h
        } @else {
          /
        }
      </p>
    </div>
    <div class="flex align-items-center gap-3 flex-grow-1">
      <p class="text-color flex-grow-1">
        @if (form.get('hourly_rate')?.value) {
          {{ form.get('hourly_rate')?.value | currency: 'EUR':'' }} €
        } @else {
          /
        }
      </p>
      @if (httProcess()) {
        <p-button (onClick)="adjustParameter()" class="adjust-button" severity="secondary">Adjust Parameter
        </p-button>
      }

      <i (click)="handleDeletion()" class="pi pi-trash cursor-pointer"></i>
    </div>
  </form>
}
