<div class="htt-header-row-div flex gap-3 w-full">
  <p [ngStyle]="{'min-width.px': dragHandleWidth}" class="text-color column-title"></p>
  <p [ngStyle]="{'min-width.px': positionWidth}" class="text-color column-title">Position</p>
  <p [ngStyle]="{'min-width.px': processWidth}" class="text-color column-title">Process</p>
  <p [ngStyle]="{'min-width.px': workCenterWidth}" class="text-color column-title">Workcenter</p>
  <p [ngStyle]="{'min-width.px': taskWidth}" class="text-color column-title">Task</p>
  <p [ngStyle]="{'min-width.px': speedWidth}" class="text-color column-title">Speed</p>
  <p [ngStyle]="{'min-width.px': setupTimeWidth}" class="text-color column-title">Setup time</p>
  <p [ngStyle]="{'min-width.px': hourlyRateMinWidth}" class="text-color flex-grow-1 column-title">Hourly rate</p>
</div>
