<div class="adjust-parameter-dialog flex flex-column gap-3">
  <p class="title">Adjust Parameters</p>
  <div class="table-div">
    <p-table [value]="processParameters()">
      <ng-template pTemplate="header">
        <tr>
          <th [ngStyle]="{'width.px': keyWidth}" class="text-color">Key</th>
          <th [ngStyle]="{'width.px': ogValueWidth}" class="text-color">OG Value</th>
          <th class="text-color">Adjusted Value</th>
        </tr>
      </ng-template>
      <ng-template let-parameter pTemplate="body">
        <tr>
          <td class="text-color">{{ parameter.name }}</td>
          <td class="text-color">{{ parameter.value }}</td>
          <td class="text-color">
            @if (checkIfProcessParameterIsEditable(parameter)) {
              <p-inputNumber
                [ngModel]="parameter.adjusted_value"
                mode="decimal"
                [minFractionDigits]="0"
                [maxFractionDigits]="10"
                [locale]="'en-US'"
                (onInput)="changeAdjustedValue($event, parameter)"
              />
              <!--              {{ parameter.adjusted_value }}-->
            }
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="speedOverview flex justify-content-end">
      <div class="summary">
        <div class="grid">
          <p class="sub-title col-4">Pieces/h</p>
          <p class="sub-title col-8">Cost per Piece/100 pc</p>
        </div>
        <div class="grid">
          <p class="card-data col-4">{{ formulaResult()?.speed ?? 0 }}</p>
          <p
            class="card-data col-8">{{ formulaResult()?.cost_per_piece ?? 0 | currency: 'EUR':'' }}
            €</p>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button (onClick)="cancel()" label="Cancel" severity="secondary"/>
    <p-button (onClick)="save()" label="Save"/>
  </div>
</div>
