import {Component, inject, OnInit, signal} from '@angular/core';
import {Button} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {
  GeneralService,
  Project,
  ProjectOverviewService,
  ResponseGetUserUserGet,
  ResponseModelProject,
  ResponseModelUserList,
  User
} from "../../api/auto-gen";
import {MultiSelectModule} from "primeng/multiselect";
import {ToastService} from "../../shared/services/toast-service.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-create-project-component',
  standalone: true,
  imports: [
    Button,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    MultiSelectModule
  ],
  templateUrl: './create-project-component.component.html',
  styleUrl: './create-project-component.component.scss',
  providers: [ProjectOverviewService, GeneralService, ToastService],
})
export class CreateProjectComponentComponent implements OnInit {
  project = signal<Project | undefined>(undefined);
  form: FormGroup | undefined;
  projectOverviewService = inject(ProjectOverviewService);
  generalService = inject(GeneralService);
  toastService = inject(ToastService);
  allUsers = signal<User[]>([]);
  protected ref = inject(DynamicDialogRef);
  private dynamicDialogConfig: DynamicDialogConfig<Project> = inject(DynamicDialogConfig);

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.project.set(this.dynamicDialogConfig.data);
    this.fetchUsers();
    this.form = this.fb.group({
      name: [this.project()?.name, Validators.required],
      project_number: [this.project()?.project_number, Validators.required],
      item_number: [this.project()?.item_number],
      status: [this.project()?.status ?? 'open'],
      users: [this.project()?.users ?? [], Validators.required],
    });
  }

  fetchUsers() {
    this.generalService.getUserUserGet({
      searchString: '',
    })
      .pipe()
      .subscribe({
          next: (response: ResponseGetUserUserGet) => {
            if (response) {
              this.allUsers.set((response as ResponseModelUserList).data?.users ?? []);
            }
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  onSave(): void {
    this.form?.markAllAsTouched();
    if (this.form?.valid) {
      if (this.project()) {
        this.projectOverviewService.updateProjectProjectProjectsProjectUuidPut({
          projectUuid: this.project()!.project_uuid,
          projectCreate: this.form.value
        })
          .pipe()
          .subscribe({
              next: (_: ResponseModelProject) => {
                this.toastService.pushSuccessToast('Project updated successfully.');
                this.ref.close(true);
              },
              error: (error: any) => {
                this.toastService.pushErrorToast(error);
              },
            },
          );
      } else {
        this.projectOverviewService.createProjectProjectProjectsPost({projectCreate: this.form.value})
          .pipe()
          .subscribe({
              next: (_: ResponseModelProject) => {
                this.toastService.pushSuccessToast('Project created successfully.');
                this.ref.close(true);
              },
              error: (error: any) => {
                this.toastService.pushErrorToast(error);
              },
            },
          );
      }


    }
  }
}
