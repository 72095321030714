{
  "name": "smart-costing",
  "version": "0.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "api:gen": "docker run --rm -v ${PWD}:/local -u 1000:users openapitools/openapi-generator-cli:v7.7.0 generate -i https://api.smart-costing-dev.fortysix.io/openapi.json -g typescript-angular --additional-properties ngVersion=17.3.8 --additional-properties providedInRoot=true --additional-properties modelPropertyNaming=original --additional-properties useSingleRequestParameter=true --skip-validate-spec -o ./local/src/app/api/auto-gen",
    "api:update": "rm -rf src/app/api/auto-gen && npm run api:gen",
    "api:gen-win": "docker run --rm -v \"%CD%\":/local -u 1000:users openapitools/openapi-generator-cli:v7.7.0 generate -i https://api.smart-costing-dev.fortysix.io/openapi.json -g typescript-angular --additional-properties ngVersion=17.3.8 --additional-properties providedInRoot=true --additional-properties modelPropertyNaming=original --additional-properties useSingleRequestParameter=true --skip-validate-spec -o ./local/src/app/api/auto-gen",
    "api:update-win": "rmdir /S /Q src\\app\\api\\auto-gen & npm run api:gen-win"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/cdk": "^17.3.0",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@types/three": "^0.168.0",
    "@types/uuid": "^10.0.0",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.8",
    "rxjs": "~7.8.0",
    "three": "^0.168.0",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
