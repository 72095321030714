import {Component, inject, input, OnInit, output, signal} from '@angular/core';
import {
  GeneralService,
  Parameter,
  Process,
  ProcessCreate,
  ResponseModelTaskList,
  ResponseModelWorkcenterList,
  Task,
  Workcenter
} from "../../../api/auto-gen";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastService} from "../../../shared/services/toast-service.service";
import {Button} from "primeng/button";
import {DropdownChangeEvent, DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {PaginatorModule} from "primeng/paginator";
import {CurrencyPipe, DecimalPipe, NgStyle} from "@angular/common";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {AdjustParameterDialogComponent} from "../../adjust-parameter-dialog/adjust-parameter-dialog.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {setupTimeWidth, speedWidth, taskWidth, workCenterWidth} from "../column-widths";
import {OptionUpsertService} from "../../option-upsert-service.service";


@Component({
  selector: 'app-htt-row',
  standalone: true,
  imports: [
    Button,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    PaginatorModule,
    ReactiveFormsModule,
    NgStyle,
    CurrencyPipe,
    DecimalPipe
  ],
  templateUrl: './htt-row.component.html',
  styleUrl: './htt-row.component.scss',
  providers: [GeneralService, ToastService, DialogService, DynamicDialogRef],
})
export class HttRowComponent implements OnInit {
  httProcess = input<Process | undefined>(undefined);
  processUuid = input.required<string>();
  mainProcess = input.required<string>();
  processChanged = output();
  site = input.required<string>();
  allValid = input.required<boolean>();
  onDelete = output<string>();
  form: FormGroup | undefined;
  workcenters = signal<Workcenter[]>([]);
  tasks = signal<Task[]>([]);
  protected readonly workCenterWidth = workCenterWidth;
  protected readonly taskWidth = taskWidth;
  protected readonly speedWidth = speedWidth;
  protected readonly setupTimeWidth = setupTimeWidth;
  private generalService = inject(GeneralService);
  private toastService = inject(ToastService);
  private dialogService = inject(DialogService);
  private dynamicDialogRef = inject(DynamicDialogRef);
  private optionUpsertService = inject(OptionUpsertService);

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      mainprocess: [this.httProcess()?.mainprocess ?? this.mainProcess(), Validators.required],
      order: [this.httProcess()?.order ?? this.optionUpsertService.getNextOrder(), Validators.required],
      workcenter: [this.httProcess()?.workcenter, Validators.required],
      task: [this.httProcess()?.task, Validators.required],
      speed: [this.httProcess()?.speed, Validators.required],
      setup_time: [this.httProcess()?.setup_time, Validators.required],
      hourly_rate: [this.httProcess()?.hourly_rate, Validators.required],
      parameters: [this.httProcess()?.parameters ?? []],
    });
    this.fetchWorkcenters();
    this.fetchTask();

  }

  async onSave(): Promise<void> {
    if (this.form?.valid && this.optionUpsertService.projectOptionUuid() && this.allValid() && this.form?.touched) {
      if (this.httProcess()) {
        await this.optionUpsertService.updateProcess(this.processUuid(), this.form.value);
      } else {
        await this.optionUpsertService.addProcess(this.form.value);
      }
    }
  }

  fetchWorkcenters(): void {
    this.generalService.getWorkcentersWorkcentersGet({
      site: this.site(),
      mainProcess: this.mainProcess()
    })
      .pipe()
      .subscribe({
          next: (response: ResponseModelWorkcenterList) => {
            this.workcenters.set(response.data?.workcenter ?? []);
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }

  fetchTask(): void {
    if (this.form?.get('workcenter')?.value) {
      this.generalService.getTasksTasksGet({
        site: this.site(),
        mainProcess: this.mainProcess(),
        workcenter: this.form!.get('workcenter')!.value
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelTaskList) => {
              this.tasks.set(response.data?.tasks ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  workCenterChanged(event: DropdownChangeEvent) {
    const selectedWorkcenter = this.workcenters().find(wc => wc.workcenter_uuid === event.value);
    this.form?.patchValue({
      task: null,
      hourly_rate: selectedWorkcenter?.hourlyrate,
    });

    this.fetchTask();
  }

  async taskChanged(event: DropdownChangeEvent) {
    let processParameters = event.value.parameters.map((e: Parameter) => ({
      constant_uuid: e.constant_uuid,
      name: e.name,
      value: e.value,
      adjusted_value: e.value,
    }));
    this.form?.patchValue({
      setup_time: event.value.setuptime,
      parameters: processParameters,
    });
    if (this.form?.value.task && this.optionUpsertService.projectOptionUuid()) {
      const selectedWorkCenter = this.workcenters().find(wc => wc.workcenter_uuid === this.form?.value.workcenter);
      if (selectedWorkCenter) {
        try {
          let formulaResult = await this.optionUpsertService.evaluateFormula(
            selectedWorkCenter,
            this.form!.value.task,
            this.form!.value.parameters,
          );
          if (formulaResult?.data) {
            this.form!.patchValue({
              speed: formulaResult.data.speed.toFixed(0),
            });
            if (this.form!.valid) {
              await this.onSave();
              this.processChanged.emit();
            }
          }
        } catch (e) {
          this.form?.patchValue({
            task: null,
          });
        }
      }
    }
  }

  handleDeletion() {

    this.dynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        confirmMessage: "Are you sure you want to delete this process?"
      },
    },);

    this.dynamicDialogRef.onClose.subscribe((deleteProcess: boolean) => {
      if (deleteProcess) {
        this.onDelete.emit(this.processUuid());
      }
    });
  }

  adjustParameter() {
    const selectedWorkCenter = this.workcenters().find(wc => wc.workcenter_uuid === this.form?.value.workcenter);
    if (selectedWorkCenter && this.httProcess()) {
      this.dynamicDialogRef = this.dialogService.open(AdjustParameterDialogComponent, {
        data: {process: this.httProcess()!, workcenter: selectedWorkCenter, task: this.httProcess()!.task},
        width: '700px'
      },);

      this.dynamicDialogRef.onClose.subscribe(async (processCreate: ProcessCreate) => {
        if (processCreate) {
          await this.optionUpsertService.updateProcess(this.processUuid(), processCreate);
        }
      });
    }

  }
}
