import {Component, inject, OnInit, signal} from '@angular/core';
import {AppBarComponent} from "../shared/app-bar/app-bar.component";
import {ProcessComponentComponent} from "./process-component/process-component.component";
import {ConstantsComponentComponent} from "./constants-component/constants-component.component";
import {FormulaComponentComponent} from "./formula-component/formula-component.component";
import {RuleComponentComponent} from "./rule-component/rule-component.component";
import {GeneralService, ResponseModelSiteList, Site} from "../api/auto-gen";
import {ToastService} from "../shared/services/toast-service.service";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AdminPageFilterService} from "./services/admin-page-filter.service";
import {HighlightingService} from "./services/highlighting.service";
import {ConstantsService} from "./services/constants.service";

@Component({
  selector: 'app-admin-panel',
  standalone: true,
  imports: [
    AppBarComponent,
    ProcessComponentComponent,
    ConstantsComponentComponent,
    FormulaComponentComponent,
    RuleComponentComponent,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './admin-panel.component.html',
  styleUrl: './admin-panel.component.scss',
  providers: [GeneralService, ToastService, AdminPageFilterService, ConstantsService, HighlightingService],
})
export class AdminPanelComponent implements OnInit {
  sites = signal<Site[]>([]);
  protected adminPageFilterService = inject(AdminPageFilterService);
  private generalService = inject(GeneralService);
  private toastService = inject(ToastService)

  ngOnInit() {
    this.fetchSites();
  }

  fetchSites() {
    this.generalService.getSitesSitesGet()
      .pipe()
      .subscribe({
          next: (response: ResponseModelSiteList) => {
            if (response.data) {
              this.sites.set(response.data!.sites);
              this.adminPageFilterService.selectedSite.set(this.sites()[0].name)
            }

          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }
}
