<div class="user-screen">
  <div class="flex w-full justify-content-between align-items-center">
    <p class="screen-title text-color">Projects</p>
    <p-button (onClick)="addProject()">Create Project</p-button>
  </div>
  <app-project-state-filter-buttons-component (onChanged)="stateFilterChanged($event)" [selected]="stateFilter()"/>
  @defer (on viewport) {
    <div class="project-list-div flex flex-column gap-3">
      <div class="filter-bar flex flex-column gap-3 sm:flex-row sm:gap-0">
        <div class="sm:col-6 xl:col-8">
      <span class="p-input-icon-left search-field">
        <i class="pi pi-search"></i>
      <input [(ngModel)]="fullTextSearch" (input)="fetchProjects()" pInputText placeholder="Search">
      </span>
        </div>
        <div class="sm:col-6 xl:col-4">
          <p-dropdown
            [options]="users()"
            [(ngModel)]="selectedUser" class="flex-grow-1"
            [showClear]="true"
            [optionLabel]="'${}'" placeholder="All Team Members"
            (onChange)="fetchProjects()"
          >
            <ng-template let-user pTemplate="selectedItem">
              @if (!user) {
                <div>All Team Members</div>
              } @else {
                <div class="inline-flex align-items-center gap-2 px-1">
                  <div>{{ user?.first_name }} {{ user?.last_name }}</div>
                </div>
              }
            </ng-template>
            <ng-template let-user pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ user.first_name }} {{ user.last_name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

      </div>
      <app-project-list-component [projects]="projects()"/>
    </div>

  } @placeholder {
    <div class="placeholder"></div>
  }
</div>
