import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {finalize} from "rxjs";
import {LoaderService} from "./services/loader.service";

export const loadingSpinnerInterceptorFunctional: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoaderService); // Instantiate the loading service
  loadingService.startLoading();

  return next(req).pipe(
    finalize(() => {
      loadingService.endLoading();
    })
  );
};
