@if (form) {
  <form [formGroup]="form">
    <div class="reference-file-div flex flex-column gap-3">
      <div class="reference-file-card flex flex-column gap-3">
        <p class="title">Reference File</p>
        @if (!optionUpsertService.projectOption()?.reference_file && !fileBufferArray()) {
          <div class="file-uploader flex flex-column gap-2">
            <p class="info-text">Upload Reference File here</p>
            <div class="upload-container">
              <p-fileUpload
                #fileUpload
                (uploadHandler)="uploadFile($event)"
                auto
                chooseLabel="Upload"
                class="w-full"
                customUpload="true"
                fileLimit="1"
                mode="advanced"
                showCancelButton="false">
                <ng-template pTemplate="empty">
                  <p-button (click)="fileUpload.choose()" class="select-area" icon="pi pi-upload" label="Upload File"/>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>
        }
        <div class="file-upsert grid">
          <div class="image-features grid grid-nogutter">
            <div class="col-8">
              @if (fileBufferArray()) {
                <div class="image-div">
                  <app-stl-model-viewer class="viewer" [bufferArray]="fileBufferArray()!"/>

                  <p-fileUpload
                    (uploadHandler)="uploadFile($event)"
                    auto
                    chooseLabel="Upload new file"
                    customUpload="true"
                    fileLimit="1"
                    class="new-file"
                    mode="basic"/>
                </div>
              }
            </div>
            <div class="col-4">
              @if (optionUpsertService.projectOption()?.reference_file_properties) {
                <div class="features">
                  <app-reference-file-properties-table
                    [referenceFileProperties]="optionUpsertService.projectOption()!.reference_file_properties!"/>
                </div>
              }
            </div>
          </div>
          @if (optionUpsertService.projectOption()?.reference_file_settings) {
            <div class="layouting-upsideDown grid grid-nogutter">
              <div class="col-8">
                <div class="layouting flex flex-column gap-3">
                  <p class="card-title">Axes Layouting:</p>
                  <div class="radio-buttons grid">
                    <div class="field-checkbox col-3">
                      <div class="field-checkbox-div flex justify-content-between cursor-pointer"
                           (click)="updateLayouting('none')">
                        <label [for]="'none'" class="ml-2 radio-label">
                          None
                        </label>
                        <p-radioButton formControlName="axes_layouting"
                                       [inputId]="'none'" [value]="'none'"
                        />
                      </div>
                    </div>
                    <div class="field-checkbox col-3">
                      <div class="field-checkbox-div flex justify-content-between cursor-pointer"
                           (click)="updateLayouting('original')">
                        <label [for]="'original'" class="ml-2 radio-label">
                          Original
                        </label>
                        <p-radioButton formControlName="axes_layouting" [inputId]="'original'"
                                       [value]="'original'"
                        />
                      </div>
                    </div>
                    <div class="field-checkbox col-3">
                      <div class="field-checkbox-div flex justify-content-between cursor-pointer"
                           (click)="updateLayouting('clockwise')">
                        <label [for]="'clockwise'" class="ml-2 radio-label">
                          Clockwise
                        </label>
                        <p-radioButton formControlName="axes_layouting"
                                       [inputId]="'clockwise'" [value]="'clockwise'"
                        />
                      </div>
                    </div>
                    <div class="field-checkbox col-3">
                      <div class="field-checkbox-div flex justify-content-between cursor-pointer"
                           (click)="updateLayouting('counterclockwise')">
                        <label [for]="'counterclockwise'" class="ml-2 radio-label">
                          Counterclockwise
                        </label>
                        <p-radioButton formControlName="axes_layouting"
                                       [inputId]="'counterclockwise'" [value]="'counterclockwise'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="upsideDown flex flex-column gap-3">
                  <p class="card-title">Upside Down:</p>
                  <div class="radio-buttons grid">
                    <div class="field-checkbox col-6">
                      <div class="field-checkbox-div flex justify-content-between cursor-pointer"
                           (click)="updateUpsideDown('original')">
                        <label [for]="'original_upside_down'" class="ml-2 radio-label">
                          Original
                        </label>
                        <p-radioButton formControlName="upside_down" [inputId]="'original_upside_down'"
                                       [value]="'original'"
                        />
                      </div>
                    </div>
                    <div class="field-checkbox col-6">
                      <div class="field-checkbox-div flex justify-content-between cursor-pointer"
                           (click)="updateUpsideDown('flipped')">
                        <label [for]="'flipped'" class="ml-2 radio-label">
                          Flipped
                        </label>
                        <p-radioButton formControlName="upside_down"
                                       [inputId]="'flipped'" [value]="'flipped'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          }
          @if (optionUpsertService.projectLayers().length > 0) {
            <div class="layers w-full">
              <app-layers-table [layers]="optionUpsertService.projectLayers()!"/>
            </div>
          }

        </div>

      </div>
      @if (optionUpsertService.projectOption()?.reference_file) {
        <div class="settings-card">
          <app-custom-slider formControlName="density" [label]="'Density(g/cm3)'"/>
          <app-custom-slider formControlName="pressure" [label]="'Pressure(N/mm2)'"/>
          <app-custom-slider formControlName="min_step_height" [label]="'Min. Step Height(mm)'"/>
          <app-custom-slider formControlName="min_layer_area" [label]="'Min. Layer Area(mm2)'"/>
          <app-custom-slider formControlName="max_angle_on_punch" [label]="'Max. Angle on Punch(deg)'"/>
          <app-custom-slider formControlName="max_layer_bend" [label]="'Max. Layer Bend(deg)'"/>
          <p-button (onClick)="optionUpsertService.detectLayers(form.value)">Apply</p-button>
        </div>
      }

    </div>
  </form>
}

