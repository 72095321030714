import {computed, inject, signal} from '@angular/core';
import {
  CostingProcessService,
  Layer,
  Process,
  ProcessCreate,
  ProcessList,
  ProcessParameter,
  ProcessReorderList,
  Project,
  ProjectOption,
  ProjectOptionCreate,
  ProjectOverviewService,
  ReferenceFileSettings,
  ResponseModelFormulaResult,
  ResponseModelLayerList,
  ResponseModelProcessList,
  ResponseModelProcessReorderList,
  ResponseModelProject,
  ResponseModelProjectOption,
  ResponseModelSimilarItemList,
  SimilarItem,
  Task,
  Workcenter
} from "../api/auto-gen";
import {ToastService} from "../shared/services/toast-service.service";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {firstValueFrom} from "rxjs";

export class OptionUpsertService {
  projectUuid = signal<string>('');
  project = signal<Project | undefined>(undefined);
  projectOptionUuid = signal<string | undefined>(undefined);
  projectOption = signal<ProjectOption | undefined>(undefined);
  similarItems = signal<SimilarItem[]>([]);
  projectLayers = signal<Layer[]>([])
  forProcess = signal<Process | undefined>(undefined);
  sizProcess = signal<Process | undefined>(undefined);
  processList = signal<ProcessList | undefined>(undefined);
  httProcesses = signal<Process[]>([]);

  allowEditing = computed(() => {
    if (this.project() && this.projectOption()) {
      return this.project()!.status == 'open' && this.projectOption()!.status == 'open';
    } else {
      return true;
    }

  })

  skipSizing = new FormControl<boolean>(false);
  private costingProcessService = inject(CostingProcessService);
  private projectOverviewService = inject(ProjectOverviewService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  initializeUpsertService(projectUuid: string, projectOptionUuid: string | undefined) {
    this.projectUuid.set(projectUuid);
    this.fetchProject();
    this.projectOptionUuid.set(projectOptionUuid);
    if (this.projectOptionUuid()) {
      this.fetchProcesses();
      this.fetchProjectOption();
    }
  }

  fetchProject() {
    this.projectOverviewService.getProjectProjectProjectsProjectUuidGet({
      projectUuid: this.projectUuid(),
    })
      .pipe()
      .subscribe({
          next: (response: ResponseModelProject) => {
            if (response.data) {
              this.project.set(response.data!);
            }

          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }


  fetchProjectOption() {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getProjectOptionCostingProjectOptionsProjectOptionUuidGet(
        {projectOptionUuid: this.projectOptionUuid()!},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProjectOption) => {
              if (response.data) {
                this.projectOption.set(response.data);
                this.skipSizing.setValue(response.data.skip_sizing ?? false);
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchProcesses() {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(
        {projectOptionUuid: this.projectOptionUuid()!},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcessList) => {
              if (response.data) {
                this.processList.set(response.data)
                this.httProcesses.set((response.data.processes ?? []).filter(x => x.mainprocess == 'HTT'));
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  reOrderProcesses(processReorder: ProcessReorderList) {
    if (this.projectOptionUuid()) {
      this.costingProcessService.reorderProcessesCostingReorderProcessesPost(
        {processReorderList: processReorder},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcessReorderList) => {
              if (response) {
                this.fetchProcesses();
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  addProjectOption(projectOptionCreate: ProjectOptionCreate) {
    if (this.projectUuid()) {
      this.costingProcessService.createProjectOptionCostingProjectsProjectUuidProjectOptionsPost(
        {projectUuid: this.projectUuid()!, projectOptionCreate: projectOptionCreate},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProjectOption) => {
              if (response.data) {
                this.projectOption.set(response.data);
                this.projectOptionUuid.set(response.data.project_option_uuid);
                this.router.navigate(['projects', this.projectUuid(), 'options', this.projectOptionUuid()], {queryParams: {step: 'referenceFile'}});
              }
              this.toastService.pushSuccessToast('ProjectOption created successfully.');
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  updateProjectOption(newName?: string, skipSizing?: boolean) {
    if (this.projectOptionUuid()) {
      this.costingProcessService.updateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatch(
        {projectOptionUuid: this.projectOptionUuid()!, newName: newName, skipSizing: skipSizing},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProjectOption) => {
              if (response.data) {
                this.fetchProjectOption();
              }
              this.toastService.pushSuccessToast('ProjectOption updated successfully.');
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  detectLayers(settings: ReferenceFileSettings) {
    if (this.projectOptionUuid()) {
      this.costingProcessService.detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost({
        projectOptionUuid: this.projectOptionUuid()!,
        referenceFileSettings: settings,
      })
        .pipe()
        .subscribe({
            next: (response: ResponseModelLayerList) => {
              if (response.data) {
                this.projectLayers.set(response.data.layers);
                this.fetchProjectOption();
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchFORProcess() {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(
        {projectOptionUuid: this.projectOptionUuid()!, mainprocess: 'FOR'},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcessList) => {
              if (response.data && response.data!.processes.length == 1) {
                this.forProcess.set(response.data.processes[0]);
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchSIZProcess() {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(
        {projectOptionUuid: this.projectOptionUuid()!, mainprocess: 'SIZ'},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelProcessList) => {
              if (response.data && response.data!.processes.length == 1) {
                this.sizProcess.set(response.data.processes[0]);
              }
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  fetchSimilarItems(mainProcess: "FOR" | "SIZ") {
    if (this.projectOptionUuid()) {
      this.costingProcessService.getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet(
        {projectOptionUuid: this.projectOptionUuid()!, mainprocess: mainProcess},
      )
        .pipe()
        .subscribe({
            next: (response: ResponseModelSimilarItemList) => {
              this.similarItems.set(response.data?.similar_items ?? []);
            },
            error: (error: any) => {
              this.toastService.pushErrorToast(error);
            },
          },
        );
    }
  }

  async addProcess(processCreate: ProcessCreate) {
    if (this.projectOptionUuid()) {
      try {
        let response = await firstValueFrom(this.costingProcessService.createProcessCostingProjectOptionsProjectOptionUuidProcessPost({
            projectOptionUuid: this.projectOptionUuid()!,
            processCreate: processCreate,
          },
        ));
        if (response) {
          this.toastService.pushSuccessToast('Process ' + response.data!.process_uuid + ' added successfully!');
          this.fetchProcesses();
        }
      } catch (e) {
        this.toastService.pushErrorToast(e);
        throw e;
      }
    }
  }

  async updateProcess(process_uuid: string, processCreate: ProcessCreate) {
    if (this.projectOptionUuid()) {
      try {
        let response = await firstValueFrom(this.costingProcessService.updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut({
            projectOptionUuid: this.projectOptionUuid()!,
            processUuid: process_uuid,
            processCreate: processCreate,
          },
        ));
        if (response) {
          this.toastService.pushSuccessToast('Process ' + process_uuid + ' saved successfully!');
          this.fetchProcesses();
        }
      } catch (e) {
        this.toastService.pushErrorToast(e);
        throw e;
      }
    }
  }

  async updateFORProcess(processCreate: ProcessCreate) {
    if (this.forProcess()) {
      await this.updateProcess(this.forProcess()!.process_uuid, processCreate);
    }
  }

  async updateSIZProcess(processCreate: ProcessCreate) {
    if (this.sizProcess()) {
      await this.updateProcess(this.sizProcess()!.process_uuid, processCreate);
    }
  }

  async evaluateFormula(workCenter: Workcenter, task: Task, parameters: ProcessParameter[]): Promise<ResponseModelFormulaResult | undefined> {
    if (this.projectOption() && workCenter.workcenter_uuid && task.task_uuid) {
      try {
        let formula = await firstValueFrom(this.costingProcessService.readFormulasCostingFormulaGet({
          site: this.projectOption()!.site,
          workcenterUuid: workCenter.workcenter_uuid,
          taskUuid: task.task_uuid,
        }));
        if (formula.data) {
          return await firstValueFrom(this.costingProcessService.evaluateFormulaCostingEvaluateFormulaPost({
                formulaUuid: formula.data?.formula_uuid,
                optionUuid: this.projectOptionUuid()!,
                bodyEvaluateFormulaCostingEvaluateFormulaPost: {
                  task: task,
                  workcenter: workCenter,
                  adjusted_parameters: parameters,
                },
              },
            ),
          );
        }
        return undefined;
      } catch (error) {
        this.toastService.pushErrorToast(error);
        throw error;
      }
    }
    return undefined;
  }

  getNextOrder(): number {
    let allProcesses = this.processList()?.processes ?? [];
    if (allProcesses.length == 0) {
      return 0;
    }

    const maxOrder = Math.max(...allProcesses.map(e => e.order));
    return maxOrder + 1;
  }
}
