import {Component, inject, OnInit, signal} from '@angular/core';
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {NgClass} from "@angular/common";
import {GeneralService, MainProcess, ResponseModelMainProcessList} from "../../api/auto-gen";
import {ToastService} from "../../shared/services/toast-service.service";
import {AdminPageFilterService} from "../services/admin-page-filter.service";

@Component({
  selector: 'app-process-component',
  standalone: true,
  imports: [
    Button,
    Ripple,
    NgClass
  ],
  templateUrl: './process-component.component.html',
  styleUrl: './process-component.component.scss',
  providers: [GeneralService, ToastService],
})
export class ProcessComponentComponent implements OnInit {
  mainProcesses = signal<MainProcess[]>([]);
  protected adminPageFilterService = inject(AdminPageFilterService);
  private generalService = inject(GeneralService);
  private toastService = inject(ToastService)

  ngOnInit() {
    this.fetchMainProcesses()
  }

  fetchMainProcesses() {
    this.generalService.getMainProcessesMainprocessesGet()
      .pipe()
      .subscribe({
          next: (response: ResponseModelMainProcessList) => {
            this.mainProcesses.set(response.data?.main_processes ?? []);
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }
}
