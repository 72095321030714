import {Component, inject, input, OnInit} from '@angular/core';
import {SimilarItem} from "../../api/auto-gen";
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {ProgressBarModule} from "primeng/progressbar";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {InputNumberModule} from "primeng/inputnumber";
import {DividerModule} from "primeng/divider";
import {SizingUpsertComponentComponent} from "./sizing-upsert-component/sizing-upsert-component.component";
import {Button} from "primeng/button";
import {CurrencyPipe, DecimalPipe} from "@angular/common";
import {OptionUpsertService} from "../option-upsert-service.service";

@Component({
  selector: 'app-sizing-component',
  standalone: true,
  imports: [
    PrimeTemplate,
    TableModule,
    ProgressBarModule,
    ReactiveFormsModule,
    InputNumberModule,
    DividerModule,
    SizingUpsertComponentComponent,
    Button,
    DecimalPipe,
    CurrencyPipe
  ],
  templateUrl: './sizing-component.component.html',
  styleUrl: './sizing-component.component.scss',
})
export class SizingComponentComponent implements OnInit {
  formGroupName = input<string>('');
  form: FormGroup | undefined;
  mainProcess: 'SIZ' = 'SIZ';
  protected optionUpsertService = inject(OptionUpsertService)
  private rootFormGroup = inject(FormGroupDirective);

  ngOnInit() {
    this.optionUpsertService.fetchSimilarItems(this.mainProcess);
    this.form = this.rootFormGroup.control.get(this.formGroupName()) as FormGroup;
    this.form.get('speed')?.enable();
    this.form.get('setup_time')?.enable();
    this.form.get('workcenter')?.enable();
    this.form.get('task')?.enable();
  }

  applySimilarItem(item: SimilarItem) {
    if (this.form) {
      this.form.patchValue({
        workcenter: item.workcenter_uuid,
        speed: item.speed,
        setup_time: item.setup_time,
        hourly_rate: item.hourly_rate,
      })
    }
  }
}
