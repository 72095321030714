<div class="project-overview-div flex flex-column gap-3">
  <div class="flex w-full align-items-center gap-3">
    <i (click)="location.back()" class="pi pi-arrow-left back-icon text-color font-bold cursor-pointer"></i>
    <p class="screen-title text-color flex-grow-1">{{ project()?.name ?? '' }}</p>
    @if (project()?.status == 'open') {
      <p-button severity="secondary" (onClick)="openEditDialog()">Edit Project</p-button>
      <p-button [queryParams]="{step: 'spaceSetup'}" [routerLink]="'./new'">New Option</p-button>
    }
  </div>
  <app-project-option-state-filter-buttons-component (onChanged)="stateFilterChanged($event)"
                                                     [selected]="stateFilter()"/>
  <app-project-option-list-component (onArchiveOption)="archiveOption($event)"
                                     (onDuplicateOption)="duplicateOption($event)"
                                     [isProjectOpen]="project()?.status == 'open'" [projectOptions]="projectOptions()"/>
  @if (project()?.status == 'open') {
    <div class="flex justify-content-end gap-2 close-buttons">
      <p-button (onClick)="closeProjectAsLost()" label="Close project as lost" severity="secondary"/>
      <p-button (onClick)="openWonDialog()" label="Close project as won" type="submit"/>
    </div>
  }

</div>
