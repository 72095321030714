<div>
  <div class="app-bar">
    <img alt="logo" class="logo" height="26" ngSrc="assets/imgs/smart-costing-logo.svg" priority width="199"/>
    <div class="vertical-divider"></div>
    <p-tabMenu (activeItemChange)="onActiveItemChange($event)" [activeItem]="activeItem" [model]="menuItems"
               class="tabs">
      <ng-template let-item pTemplate="item">
        <a [routerLink]="item.route" class="p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="ml-2">
                        {{ item.label }}
                    </span>
        </a>
      </ng-template>
    </p-tabMenu>
    <div class="spacer"></div>
    <p-menu #menu [model]="items" [popup]="true"/>
    <div (click)="menu.toggle($event)" class="user-dropdown">
      <div class="pi pi-user"></div>
      <!---TODO: change to actual user--->
      <p class="user-name">Max Mustermann</p>
      <div class="pi pi-angle-down hidden md:flex"></div>
    </div>
  </div>
  <div class="loader">
    @if (loaderService.getLoading()) {
      <p-progressBar [style]="{ height: '6px' }" mode="indeterminate"/>
    }
  </div>
</div>

