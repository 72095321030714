import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {BASE_PATH} from "./api/auto-gen";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {MessageService} from "primeng/api";
import {environment} from "../environments/environment";
import {OptionUpsertService} from "./option-upsert-page/option-upsert-service.service";
import {LoaderService} from "./shared/services/loader.service";
import {loadingSpinnerInterceptorFunctional} from "./shared/loading.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors(
        [
          loadingSpinnerInterceptorFunctional,
        ]
      )
    ),
    LoaderService,
    MessageService,
    OptionUpsertService,
    {
      provide: BASE_PATH,
      useValue: environment.apiEndpoint
    },
  ],
};
