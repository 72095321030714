<div class="project-upsert-div">
  <p class="title text-color">
    @if (project()) {
      Update Project {{ project()!.name }}
    } @else {
      Create Project
    }
  </p>
  <div class="form">
    @if (form) {
      <form [formGroup]="form" (ngSubmit)="onSave()" novalidate>
        <div class="flex flex-column align-items-start gap-3 mb-3">
          <label class="label text-color-secondary" for="projectName">Project Name *</label>
          <input class="w-full" placeholder="Name" formControlName="name" id="projectName" pInputText/>
          @if (form.get('name')?.invalid && form.get('name')?.touched) {
            <small class="p-error">
              Required
            </small>
          }
        </div>
        <div class="flex flex-column align-items-start gap-3 mb-3">
          <label class="label text-color-secondary" for="projectNumber">Project Number *</label>
          <input class="w-full" placeholder="Ex.: 000000" formControlName="project_number" id="projectNumber"
                 pInputText/>
          @if (form.get('project_number')?.invalid && form.get('project_number')?.touched) {
            <small class="p-error">
              Required
            </small>
          }
        </div>
        <div class="flex flex-column align-items-start gap-3 mb-3">
          <label class="label text-color-secondary" for="itemNumber">Item Number</label>
          <input class="w-full" placeholder="Ex.: 000000" formControlName="item_number" id="itemNumber" pInputText/>
        </div>
        <div class="flex flex-column align-items-start gap-3 mb-3">
          <label class="label text-color-secondary" for="users">Project Team *</label>
          <p-multiSelect formControlName="users" [options]="allUsers()" class="w-full" id="users" appendTo="body">
            <ng-template let-value pTemplate="selectedItems">
              @if (!value || value.length === 0) {
                Select Users
              }
              @for (user of value; track user) {
                <div class="flex align-items-center gap-2">
                  <div>{{ user.first_name }} {{ user.last_name }}</div>
                </div>
              }


            </ng-template>
            <ng-template let-user pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ user.first_name }} {{ user.last_name }}</div>
              </div>
            </ng-template>

          </p-multiSelect>
          @if (form.get('users')?.invalid && form.get('users')?.touched) {
            <small class="p-error">
              Required
            </small>
          }
        </div>
        <div class="flex justify-content-end gap-2">
          <p-button (onClick)="ref.close(false)" label="Cancel" severity="secondary"/>
          <p-button type="submit" label="Save"/>
        </div>
      </form>
    }
  </div>
</div>
