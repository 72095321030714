import {Component} from '@angular/core';
import {
  dragHandleWidth,
  hourlyRateMinWidth,
  positionWidth,
  processWidth,
  setupTimeWidth,
  speedWidth,
  taskWidth,
  workCenterWidth
} from "../../htt-component/column-widths";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-process-header-row',
  standalone: true,
  imports: [
    NgStyle
  ],
  templateUrl: './process-header-row.component.html',
  styleUrl: './process-header-row.component.scss'
})
export class ProcessHeaderRowComponent {

  protected readonly workCenterWidth = workCenterWidth;
  protected readonly setupTimeWidth = setupTimeWidth;
  protected readonly speedWidth = speedWidth;
  protected readonly taskWidth = taskWidth;
  protected readonly positionWidth = positionWidth;
  protected readonly processWidth = processWidth;
  protected readonly dragHandleWidth = dragHandleWidth;
  protected readonly hourlyRateMinWidth = hourlyRateMinWidth;
}
