import {Component, input} from '@angular/core';
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {Layer} from "../../../api/auto-gen";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'app-layers-table',
  standalone: true,
  imports: [
    PrimeTemplate,
    TableModule,
    DecimalPipe
  ],
  templateUrl: './layers-table.component.html',
  styleUrl: './layers-table.component.scss'
})
export class LayersTableComponent {
  layers = input.required<Layer[]>();
}
